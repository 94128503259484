export const environment = {
  name: 'staging',
  auth: {
    clientID: 'AwLTrdpZnF5q0taVVVlxMPeEPT1T4ksh',
    domain: 'qa-login.zonarsystems.net',
    audience: 'http://apiqa.zonarsystems.net/',
  },
  datadog: {
    applicationId: '7e69f71c-18d7-4c7e-a0d2-e6ecd583ffeb',
    clientToken: 'pub7a333b389627c049768aef67c8949c33',
    site: 'us5.datadoghq.com',
    service: 'driver-dashboard',
  },
  production: false
};
